import axios from "axios";
import { auth } from "./Auth/auth";

const PREFIX_V1 = "/v1/api";

export const doLogin = (credentials) => {
  return axios
    .post(`/auth/login`, credentials)
    .then((resp) => resp.data)
    .catch((err) => err);
};

export const getUsers = () => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .get(`${PREFIX_V1}/users`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const getUser = (id) => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .get(`/v2/api/users/${id}`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const updateUser = (data, id) => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };
  return axios
    .put(`/v1/api/users/${id}`, data, config)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getPosts = () => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .get(`${PREFIX_V1}/posts`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const getPost = (id) => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .get(`${PREFIX_V1}/posts/${id}/user`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const deleteUser = (id) => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .delete(`${PREFIX_V1}/users/${id}`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const deletePost = (row) => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .delete(`${PREFIX_V1}/posts/${row.id}`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const resetUser = (email) => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .get(`/auth/send-email/${email}/forgot-password`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const checkUsernameEmail = (username, email) => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .get(`/auth/check-username-email/${username}/${email}`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const getHomeData = () => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .get(`/auth/home/data`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const deleteUserImage = (id) => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .delete(`${PREFIX_V1}/users/delete-image/${id}`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const getExperiences = () => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .get(`${PREFIX_V1}/experience/getAllOriginal`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const getBackground = () => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .get(`${PREFIX_V1}/background/getAllOriginal`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const getParentPositions = () => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .get(`${PREFIX_V1}/position/getAllOriginal`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};

export const getChildrenPositions = () => {
  const { access_token } = auth.getData();
  const config = { headers: { authorization: `Bearer ${access_token}` } };

  return axios
    .get(`${PREFIX_V1}/position/children`, config)
    .then((resp) => resp.data)
    .catch((err) => console.log(err));
};
