import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { List, ListItem, PostVideo, PostPhoto, PostNone, DeletePost } from "../styles/styled-elements";
import Layout from "../components/Layout";
import { getPosts } from "../../../components/requests";
import moment from "moment";
import PropTypes from "prop-types";
import { CustomLoadingOverlay } from "./components/loadingBar";
import { QuickSearchToolbar } from "./components/SearchBarStyles";
import { escapeRegExp } from "./UserList";
import CardMedia from '@mui/material/CardMedia';
import { deletePost } from "../../../components/requests";
import { Link } from "react-router-dom";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Tooltip } from '@mui/material';


const PostsList = () => {
  const [pageSize, setPageSize] = useState(10);  
  const [tableData, setTableData] = useState([]);
  const [tableDataOriginal, setTableDataOriginal] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [modalMedia, setModalMediaParams] = useState([]);
  const [deleted, setDeleted] = useState("");

  useEffect(() => {
    getPosts().then((data) => {
      setTableData(data.data);
      setTableDataOriginal(data.data);
      setLoading(false);
    });
  }, [deleted]);

  const [open, setOpen] = useState(false);

  const handleClickOpenModal = (row) => {
    setOpen(true);
    setDeleted(row)
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseAcceptModal = () => {
    deletePost(deleted)
    setOpen(false);
    setDeleted("")

    getPosts().then((data) => {
      setTableData(data.data);
      setLoading(false);
    });
  }

  const [selectedModal, setSelectedModal] = useState({  url: '', description: '', type: '', open: false });

  const DialogRender = () => {
    return (
      <Dialog
        open={selectedModal.open}
        onClose={handleCloseMediaModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        BackdropProps={{
          style: {
            backgroundColor: 'rgb(0 0 0 / 10%)',
            boxShadow: 'none',
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: 'white',
            boxShadow: 'none',
          },
        }}>
        <DialogTitle id='alert-dialog-title'>{'View Post Media'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <h1>{selectedModal.description}</h1>
            <CardMedia 
            style={{ maxWidth: '400px' }}
            component={selectedModal.type} 
            image={selectedModal.url} 
            maxHeight="200"
            autoPlay 
            controls
            />
            <br />

            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMediaModal} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleClickOpen = (e, url, description, type) => {
    e.preventDefault();
    setSelectedModal({ url: url, description:description, type: type, open: true });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedModal({ url: '', description: '', type: '', open: false });
  };

  const handleCloseMediaModal = () => {
    setModalMediaParams([]);
    setOpen(false);
    setSelectedModal({  url: '', description: '', type: '', open: false });
  };

  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = tableDataOriginal.filter((row) => {
      return Object.keys(row).some((field) => {
        if(field == 'position'){
          return searchRegex.test(row[field].name.toString());
        }
        if(field == 'user'){
          return searchRegex.test(row[field].name.toString());
        }
        return searchRegex.test(row[field].toString());
      });
    });

    setTableData(filteredRows);
  };

  function getPositions(params) {
    if(params.row.position){
      const positions = params.row.position;
      let nameWithUppercase = positions.parent.name.charAt(0).toUpperCase() + positions.parent.name.slice(1);
      return `${nameWithUppercase || ''}, ${positions.name || ''}`;
    }else{
      return '';
    }
  }

  const columns = [
    {
      field: 'url',
      headerName: 'Post',
      width: 120,
      type: 'string',
      filterable: false,
      renderCell: (params) => {
        if (['jpg', 'png'].includes(params.row.url.substr(params.row.url.length - 3).toLowerCase())) {
          return (
            <>
              <PostPhoto
                variant='outlined'
                onClick={(e) => {
                  setModalMediaParams([params.row.url, params.row.description, 'img']);
                  handleClickOpen(e, params.row.url, params.row.description, 'img');
                }}>
                Dialog
              </PostPhoto>
            </>
          );
        } else if (['mp4', 'mpg', 'avi', 'mov'].includes(params.row.url.substr(params.row.url.length - 3).toLowerCase())) {
          return (
            <>
              <PostVideo
                variant='outlined'
                onClick={(e) => {
                  setModalMediaParams([params.row.url, params.row.description, 'video']);
                  handleClickOpen(e, params.row.url, params.row.description, 'video');
                }}>
                Dialog
              </PostVideo>
            </>
          );
        } else {
          return (
            <>
              <PostNone
                variant='outlined'
                onClick={(e) => {
                  handleClickOpen(e, params.row.url, params.row.description, 'img');
                }}>
                Dialog
              </PostNone>
            </>
          );
        }
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      type:'string',
      renderCell: (params) => {
        return (
          <Link
            style={{ "text-decoration": "none", color: "inherit" }}
            to={"/user/" + params.row.user.id}
            target="_blank"
          >
            <ListItem>{params.row.user.name.replace(',','')}</ListItem>
          </Link>);
      },
      valueGetter: (params) => params.row.user.name
    },
    { field: 'description', 
    headerName: 'Description', 
    width: 200,
    type:'string',
    renderCell: (params) =>  {
    if(params.value === undefined) {
      return (
        <Tooltip title='' enterDelay={1000} placement="top-start" arrow>
         <span style={{overflowX: 'hidden', textOverflow: 'ellipsis' }}></span>
        </Tooltip>
       );
    } else
    {
      return (
        <Tooltip title={params.value} enterDelay={1000} placement="top-start" arrow>
         <span style={{overflowX: 'hidden', textOverflow: 'ellipsis' }} >{params.value}</span>
        </Tooltip>
       );
    }}
    },
    {
      field: 'position',
      headerName: 'Role',
      width: 300,
      type:'string',
      valueGetter: getPositions,
      renderCell: (params) =>  (
        <Tooltip title={params.value } enterDelay={1000} placement="top-start" arrow>
         <span style={{overflowX: 'hidden', textOverflow: 'ellipsis' }} >{params.value}</span>
        </Tooltip>
       ),
    },
    { field: 'location', headerName: 'Location', width: 200, type:'string' },
    {
      field: 'updatedAt',
      headerName: 'Date',
      width: 150,
      type: 'dateTime',
      valueGetter: (params) => moment(params.row.updatedAt).toDate(),
      renderCell: (params) => {
        return moment(params.row.updatedAt).format('MM/D/YYYY HH:mm');
      },
    },
    { field: 'is_public', headerName: 'Is public', width: 150, type:'boolean' },
    { field: 'id', headerName: 'ID', width: 200, type:'string' },
    {
      field: "deletePost",
      headerName: "Delete Post",
      width: 200,
      type: "string",
      renderCell: (params) => {
        return (
          <>
              <DeletePost variant="outlined" onClick={()=>{handleClickOpenModal(params.row)}}>
                Open alert dialog
              </DeletePost>
              <Dialog
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{
                  style: {
                    backgroundColor: 'rgb(0 0 0 / 10%)',
                    boxShadow: 'none',
                  }
                }}
                PaperProps={{
                  style: {
                    backgroundColor: 'white',
                    boxShadow: 'none',
                  }
                }}
              >
                <DialogTitle id="alert-dialog-title">
                  {"Admin - Delete post"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this post?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseModal}>Disagree</Button>
                  <Button onClick={handleCloseAcceptModal} autoFocus>
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
          </>
        );
      },
    }
  ];

  return (
    <Layout
      children={
        <>
          <List>
            <DataGrid
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
              }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(''),
                },
              }}
              loading={loading}
              rows={tableData}
              disableSelectionOnClick
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          </List>
          {DialogRender()}
        </>
      }
    />
  );
};

export default PostsList;
