import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { device } from './device'
import GigFixLogo from '../dashboard/gigfix.png';
import axios from 'axios'

function RecoverPassword() {
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [error, setError] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const [disable, setDisable] = useState(true);


  const getQueryVariable = (param) =>
  {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
    if(pair[0] == param){return pair[1];}
    }
    return(false);
  }


  const hash = getQueryVariable('h')
  const iv = getQueryVariable('i')

  useEffect(() => {
    setDisable(password === "")
  }, [password])

  const handleChange = e => {
    setPassword(e.target.value)
  }

  const changePassword = (hash, iv, password)=>{

    return axios({
        method: 'post', 
        data: {
              content: hash, 
              iv: iv, 
              password: password
            }, 
        url: '/auth/forgot-password'
    })
    .then(res => res)
    .then(err => err)
  }

  const submitHandler = async e => {
    e.preventDefault()

    if (password === "" || repeatPassword === "") {
      setError('Please fill out all of the fields')
      return;
    }

    if (password === repeatPassword) {
      const res = await changePassword(hash, iv, password);

      if(res){
        setIsToggled(!isToggled)
      }
    } else {
      setError("Passwords don't match")
      return;
    }
  }

  

  return (
    <MainWrapper>
      {!isToggled ? (
        <Form onSubmit={submitHandler}>
          <MainTitle>Set New Password</MainTitle>
          <Description>Enter your new password in the fields below.</Description>
          <InputWrapper>
            <Label htmlFor='password'>New Password</Label>
            <Input type='password' password='password' id='password' onChange={e => handleChange(e)} value={password} />
            {(error !== "") ? (<Error className='error'>{error}</Error>) : null}
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor='repeatPassword'>Confirm New Password</Label>
            <Input type='password' password='repeatPassword' id='repeatPassword' onChange={e => setRepeatPassword(e.target.value)} value={repeatPassword} />
            {(error !== "") ? (<Error className='error'>{error}</Error>) : null}
          </InputWrapper>
          <Button type='submit' disabled={disable} active={disable}>Update Password</Button>
           <Logo src={GigFixLogo} alt="GigFix Logo" />
        </Form>
      ) : (
        <Form onSubmit={submitHandler}>
          <MainTitle>Set New Password</MainTitle>
          <DescriptionChanged>Password changed!</DescriptionChanged>
          <Description>Please log in with your new password.</Description>
          <Logo src={GigFixLogo} alt="GigFix Logo" />
        </Form>
      )}
    
    </MainWrapper>
  );
}


const MainWrapper = styled.div`
  background-color: #F2F2F4;
  padding: 55px 35px 45px 35px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktop} {
    height: 100vh;
    width: 100%;
  }
`

const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;


  @media only screen and (min-width: 900px) {
    height: 100vh;
    width: 25%;
  }
`

const DescriptionChanged = styled.h1`
  margin-top: 240px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;

  @media screen and (max-width: 1650px){
    margin-top: 200px;
  }

  @media screen and (max-width: 400px){
    margin-top: 150px;
  }
`

const MainTitle = styled.h1`
  margin-bottom: 35px;
  font-weight: 300;
  font-size: 19px;
`

const Description = styled.p`
  margin-bottom: 60px;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1280px){
    text-align: center;
  }
`

const InputWrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Label = styled.label`
  font-weight: 400;
  font-size: 12px;
  margin-left: 15px;
  margin-bottom: 5px;
`

const Input = styled.input`
  height: 45px;
  border-radius: 20px;
  border: 1px #CBCBCB solid;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.15);
  padding-left: 15px;
  outline: none;
`

const Button = styled.button`
  margin-top: 35px;
  width: 100%;
  height: 45px;
  background-color: #0176B2;
  font-size: 20px;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 18px;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.15);

  ${props => props.active && (`
    opacity: 0.5;
`)}
`

const Error = styled.span`
  margin-top: 5px;
  margin-bottom: 15px;
  margin-left: 150px;
  font-size: 12px;
  color: red;
  font-weight: 400;

  @media screen and (max-width: 800px){
    margin-top: 10px;
    margin-left: 200px;
  }

  @media screen and (max-width: 1280px){
    margin-top: 10px;
    margin-left: 30px;
  }

  @media screen and (max-width: 1920px){
    margin-top: 10px;
    margin-left: 300px;
  }

  @media screen and (max-width: 1440px){
    margin-top: 10px;
    margin-left: 140px;
  }

  @media screen and (max-width: 480px){
    margin-top: 10px;
    margin-left: 150px;
  }
`

const Logo = styled.img`
  height: 60px;
  width: 150px;
  margin-top: 50px;
`;


export default RecoverPassword;
