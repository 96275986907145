import React from 'react'
import styled from 'styled-components';
import Featured from '../components/Featured';

const Home = () => {
    return (
        <HomeContainer>
            <Featured/>
        </HomeContainer>
    )
}

const HomeContainer = styled.div`
    flex: 4;
`

export default Home