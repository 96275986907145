/** @format */

import axios from "axios";
import { auth } from "../../components/Auth/auth";
import jwt_decode from "jwt-decode";

interface JWT_PAYLOAD {
  iam: number;
  userId: string;
}

export class AxiosInstance {
  private axios = axios.create();

  constructor() {
    this.axios.interceptors.request.use((config) => {
      const { access_token } = auth.getData() || {};
      this.validateJWT(access_token);
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${access_token}`;
      return config;
    });
  }

  private validateJWT = (jwt?: string | null) => {
    if (jwt) {
      const {} = jwt_decode(jwt) as JWT_PAYLOAD;
    }
  };

  public get: typeof axios.get = (...params) => this.axios.get(...params);

  public post: typeof axios.post = (...params) => this.axios.post(...params);
}
