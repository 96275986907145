import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { List } from "../../styles/styled-elements";
import { CustomLoadingOverlay } from "./loadingBar";
import moment from "moment";
import HTTPService from "../../../../service/httpService/httpService";

const PreviewUserPosts = () => {
  const [userInvites, setUserInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    HTTPService.getUserInvites(id).then((data) => {
      setUserInvites(data.data);
      setLoading(false);
    });
  }, [id]);

  const columns = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
      type: "dateTime",
      valueGetter: (params) => moment(params.row.createdAt).toDate(),
      renderCell: (params) => {
        return moment(params.row.createdAt).format("MM/D/YYYY HH:mm");
      },
    },
    { field: "email", headerName: "Email", width: 500 },
  ];

  return (
    <>
      <ListPreviewUser>
        <DataGrid
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          loading={loading}
          rows={userInvites}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
        />
      </ListPreviewUser>
    </>
  );
};

const ListPreviewUser = styled(List)`
  margin-top: 1rem;
  height: 30rem;
  width: 100%;
`;
export default PreviewUserPosts;
