import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListImage,
  Check,
  Uncheck,
  Pending,
  DeleteUser,
} from "../styles/styled-elements";
import Layout from "../components/Layout";
import { getUsers } from "../../../components/requests";
import { deleteUser } from "../../../components/requests";
import PropTypes from "prop-types";
import { CustomLoadingOverlay } from "./components/loadingBar";
import { QuickSearchToolbar } from "./components/SearchBarStyles";
import moment from "moment";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const UserList = () => {
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [tableDataOriginal, setTableDataOriginal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [userData, setUserData] = useState([]);
  const [deleted, setDeleted] = useState("");
  const [sortModel, setSortModel] = useState([
    { field: "createdAt", sort: "desc" },
  ]);

  useEffect(() => {
    getUsers().then((data) => {
      setTableData(data.data);
      setTableDataOriginal(data.data);
      setUserData(data.data);
      setLoading(false);
    });
  }, []);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);

    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = tableDataOriginal.filter((row) => {
      return Object.keys(row).some((field) => {
        if (field == "position") {
          if (row[field].length > 0) {
            let flag = false;
            row[field].forEach((x) => {
              if (searchRegex.test(x.name.toString())) {
                flag = true;
              }
            });
            return flag;
          }
        }

        if (field == "city") {
          if (searchRegex.test(row[field].primary.toString())) {
            return searchRegex.test(row[field].primary.toString());
          }
          if (searchRegex.test(row[field].secondary.toString())) {
            return searchRegex.test(row[field].secondary.toString());
          }
        }
        return searchRegex.test(row[field].toString());
      });
    });

    setTableData(filteredRows);
    if (searchValue === "") {
      setTableData(userData);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id) => {
    setOpen(true);
    setDeleted(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAccept = () => {
    deleteUser(deleted);
    setOpen(false);
    setDeleted("");
  };

  function getPositions(params) {
    let position = "";

    if (params.row.position) {
      let k = params.row.position.length;
      params.row.position.forEach((x, y) => {
        position += x.name;
        if (y + 1 < k) {
          position += " | ";
        }
      });
    }
    return position;
  }

  function getLocations(params) {
    let city = "";

    if (params.row.city) {
      city += params.row.city.primary;
      if (params.row.city.secondary || params.row.city.secondary != "") {
        city += " | " + params.row.city.secondary;
      }
    }
    return city;
  }

  const handleSortChange = (model) => {
    /* if statement to prevent the infinite loop by confirming model is 
     different than the current sortModel state */
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };

  const columns = [
    {
      field: "username",
      headerName: "Handle",
      width: 200,
      renderCell: (params) => {
        return (
          <Link
            style={{ "text-decoration": "none", color: "inherit" }}
            to={"/user/" + params.row.id}
            target="_blank"
          >
            <ListItem>
              <ListImage
                src={
                  params.row.profile_image_url ||
                  "https://gigfix-bucket.s3.amazonaws.com/img/gigfix_icon.png"
                }
                alt="image"
              />
              {`@${params.row.username}`}
            </ListItem>
          </Link>
        );
      },
    },
    { field: "completedName", headerName: "Name", width: 300 },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "position",
      headerName: "Role",
      width: 200,
      type: "string",
      valueGetter: getPositions,
      renderCell: (params) => {
        let position = "";

        if (params.row.position) {
          let k = params.row.position.length;
          params.row.position.forEach((x, y) => {
            position += x.name;
            if (y + 1 < k) {
              position += " | ";
            }
          });
        }
        return (
          <Tooltip
            title={`${position}`}
            enterDelay={1000}
            placement="top-start"
            arrow
          >
            <span>{`${position}`}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "city",
      headerName: "Location",
      width: 200,
      type: "string",
      valueGetter: getLocations,
      renderCell: (params) => {
        let city = "";

        if (params.row.city) {
          city += params.row.city.primary;
          if (params.row.city.secondary || params.row.city.secondary != "") {
            city += " | " + params.row.city.secondary;
          }
        }
        return (
          <Tooltip
            title={`${city}`}
            enterDelay={1000}
            placement="top-start"
            arrow
          >
            <span>{`${city}`}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "rate",
      headerName: "Rate",
      width: 130,
      type: "number",
      renderCell: (params) => {
        if (params.row.rate !== undefined && params.row.rate > 0) {
          return <>{`${params.row.rate} USD / hr`}</>;
        } else {
          return <>{``}</>;
        }
      },
      valueGetter: (params) => params.row.rate,
    },
    {
      field: "createdAt",
      headerName: "Registered date",
      width: 200,
      type: "dateTime",
      valueGetter: (params) => moment(params.row.createdAt).toDate(),
      renderCell: (params) => {
        return moment(params.row.createdAt).format("MM/D/YYYY HH:mm");
      },
    },
    {
      field: "invites",
      headerName: "Invites",
      width: 200,
      type: "numer",
      renderCell: (params) => {
        return params.row.invites_count;
      },
    },
    {
      field: "is_email_validated",
      headerName: "Email Validated",
      width: 170,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.is_email_validated ? <Check /> : <Uncheck />,
    },
    {
      field: "marketing_check",
      headerName: "Marketing Check",
      width: 170,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.marketing_check ? <Check /> : <Uncheck />,
    },
    {
      field: "review_status",
      headerName: "Status",
      width: 170,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          {params.row.review_status === "accepted" && <Check />}
          {params.row.review_status === "rejected" && <Uncheck />}
          {params.row.review_status === "pending" && <Pending />}
        </>
      ),
    },
    {
      field: "partner_user",
      headerName: "Partner User",
      width: 170,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.partner_user ? <Check /> : <Uncheck />,
    },
    {
      field: "deleteUser",
      headerName: "Delete",
      width: 170,
      headerAlign: "center",
      align: "center",
      type: "string",
      renderCell: (params) => {
        return (
          <>
            <DeleteUser
              variant="outlined"
              onClick={() => {
                handleClickOpen(params.row.id);
              }}
            >
              Open alert dialog
            </DeleteUser>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              BackdropProps={{
                style: {
                  backgroundColor: "rgb(0 0 0 / 10%)",
                  boxShadow: "none",
                },
              }}
              PaperProps={{
                style: {
                  backgroundColor: "white",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {"Admin - Delete user"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete the user, all their posts and
                  connections?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={handleCloseAccept} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      },
    },
  ];

  return (
    <Layout
      children={
        <List>
          <DataGrid
            components={{
              Toolbar: QuickSearchToolbar,
              LoadingOverlay: CustomLoadingOverlay,
            }}
            loading={loading}
            rows={tableData}
            disableSelectionOnClick
            columns={columns}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => {
                  requestSearch(event.target.value);
                },
                clearSearch: () => requestSearch(""),
              },
            }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            sortModel={sortModel}
            onSortModelChange={handleSortChange}
          />
        </List>
      }
    />
  );
};

export default UserList;
