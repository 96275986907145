/** @format */

import { AxiosInstance } from "./axiosInstance";
import { GetInvitesResponse, ValidateEmailResponse } from "./responses";

class HTTPService {
  private axios = new AxiosInstance();

  public getUserInvites = (userId: string) =>
    this.axios.get<GetInvitesResponse>("/v1/api/invite", {
      params: { userId },
    });

  public verifyEmail = (e: string, c: string, i: string) =>
    this.axios.post<ValidateEmailResponse>("auth/verify-email", null, {
      params: { e, c, i },
    });
}

const httpService = new HTTPService();

export default httpService;
