
const Layout = ({ children }) => {
      
      let url =  window.location.href
      let url2 = url.split('profile/')
      
      window.location = 'gigfix://profile/'+url2[1];
};


export default Layout;
