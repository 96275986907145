import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GigFixLogo from "../dashboard/gigfix.png";
import { device } from "./device";
import httpService from "../../service/httpService/httpService";
import { useLocation } from "react-router-dom";
import { LinearProgress } from "@mui/material";

interface EmailVerifiedProps {
  descriptionChanged: any;
  description: any;
}

const EmailVerified = ({
  descriptionChanged,
  description,
}: EmailVerifiedProps) => {
  return (
    <MainWrapper>
      <Form>
        <MainTitle>Email Verification</MainTitle>
        <DescriptionChanged>{descriptionChanged}</DescriptionChanged>
        <Description>{description}</Description>
        <Logo src={GigFixLogo} alt="GigFix Logo" />
      </Form>
    </MainWrapper>
  );
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function VerifyEmail() {
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const params = useQuery();
  console.log(
    params,
    params.get("e") || "",
    params.get("c") || "",
    params.get("i") || ""
  );

  useEffect(() => {
    httpService
      .verifyEmail(
        params.get("e") || "",
        params.get("c") || "",
        params.get("i") || ""
      )
      .then((response) => {
        setValidated(response.data.data.isValidated);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [params]);

  if (loading) {
    return <LinearProgress />;
  }

  if (!validated) {
    return (
      <EmailVerified
        descriptionChanged={
          <>
            <>
              <p>This email address has already been verified.</p>
            </>
            <>
              <p>
                For additional assistance, please contact us at{" "}
                <a href="mailto:support@gigfix.com">support@gigfix.com</a>.
              </p>
            </>
          </>
        }
        description={"GigFix Team"}
      />
    );
  } else {
    return (
      <EmailVerified
        descriptionChanged="Email has been verified!"
        description="Thank you for choosing GigFix"
      />
    );
  }
}

const MainWrapper = styled.div`
  background-color: #f2f2f4;
  padding: 55px 35px 45px 35px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktop} {
    height: 100vh;
    width: 100%;
  }
`;

const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 900px) {
    height: 100vh;
    width: 25%;
  }
`;

const DescriptionChanged = styled.h1`
  margin-top: 240px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;

  @media screen and (max-width: 1650px) {
    margin-top: 200px;
  }

  @media screen and (max-width: 400px) {
    margin-top: 150px;
  }
`;

const MainTitle = styled.h1`
  margin-bottom: 35px;
  font-weight: 300;
  font-size: 19px;
`;

const Description = styled.p`
  margin-bottom: 60px;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1280px) {
    text-align: center;
  }
`;

const Logo = styled.img`
  height: 60px;
  width: 150px;
`;

export default VerifyEmail;
