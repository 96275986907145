import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GigFixLogo from "../gigfix.png";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../../../components/Auth/auth";
import { doLogin } from "../../../components/requests";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";

function Login() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const [disable, setDisable] = useState(true);

  const navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";

  useEffect(() => {
    setDisable(password === "");
    setDisable(user === "");
    if (auth.isAuthenticated) navigate(from, { replace: true });
  }, [password, user]);

  useEffect(() => {
    if (isToggled) {
      // setTimeout(() => {
      //   localStorage.removeItem("token", localStorage.access_token);
      //   navigate("/login");
      // }, 604800);

      navigate(from, { replace: true });
    }
  }, [isToggled]);

  const handleChange = (e) => {
    setUser(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (user.trim() === "" || password.trim() === "") {
      setError("Please fill out all the fields");
      return;
    }

    const res = await doLogin({
      email: user.toLowerCase(),
      password: password,
    });

    if (res.response) {
      setError(res.response.data.msg);
      return;
    }

    setIsToggled(!isToggled);
    auth.signin(res.data.access_token);
  };

  return (
    <MainWrapper>
      <Form onSubmit={submitHandler}>
        <MainTitle>Administrator</MainTitle>
        <Description>Enter your information below.</Description>
        <InputWrapper>
          <Label htmlFor="user">User</Label>
          <Input
            type="text"
            user="user"
            id="user"
            onChange={(e) => handleChange(e)}
            value={user}
          />
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="password">Password</Label>
          <Input
            type="password"
            password="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </InputWrapper>
        {error !== "" ? <Error className="error">{error}</Error> : null}
        <Button type="submit" disabled={disable} active={disable}>
          Login
        </Button>
      </Form>
      <Logo src={GigFixLogo} alt="GigFix Logo" />
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  background-color: #f2f2f4;
  padding: 55px 35px 45px 35px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 900px) {
    height: 100vh;
    width: 25%;
  }
`;

const MainTitle = styled.h1`
  margin-bottom: 35px;
  font-weight: 300;
  font-size: 19px;
`;

const Description = styled.p`
  margin-bottom: 60px;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1280px) {
    text-align: center;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 12px;
  margin-left: 15px;
  margin-bottom: 5px;
`;

const Input = styled.input`
  height: 45px;
  border-radius: 20px;
  border: 1px #cbcbcb solid;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  padding-left: 15px;
  outline: none;
`;

const Button = styled.button`
  margin-top: 35px;
  width: 100%;
  height: 45px;
  background-color: #0176b2;
  font-size: 20px;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 18px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);

  ${(props) =>
    props.active &&
    `
    opacity: 0.5;
`}
`;

const Error = styled.span`
  font-size: 12px;
  color: red;
  font-weight: 400;

  @media screen and (max-width: 800px) {
    margin-top: 10px;
    margin-left: 200px;
  }

  @media screen and (max-width: 1280px) {
    margin-top: 10px;
    margin-left: 30px;
  }

  @media screen and (max-width: 1920px) {
    margin-left: 15rem;
  }

  @media screen and (max-width: 1440px) {
    margin-top: 10px;
    margin-left: 10px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 10px;
    margin-left: 150px;
  }
`;

const Logo = styled.img`
  height: 60px;
  width: 150px;
`;

export default Login;
