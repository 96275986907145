import React from "react";
import {
  LineStyle,
  DynamicFeedOutlined,
  PermIdentity,
} from "@mui/icons-material";
import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  return (
    <SidebarContainer>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarList>
            <Link
              to="/"
              className={location.pathname === "/" ? "link selected" : "link"}
            >
              <SidebarListItem>
                <DashboardIcon />
                Home
              </SidebarListItem>
            </Link>
            <Link
              to="/users"
              className={
                location.pathname === "/users" ? "link selected" : "link"
              }
            >
              <SidebarListItem>
                <PersonIcon />
                Users
              </SidebarListItem>
            </Link>
            <Link
              to="/posts"
              className={
                location.pathname === "/posts" ? "link selected" : "link"
              }
            >
              <SidebarListItem>
                <PostsIcon />
                Posts
              </SidebarListItem>
            </Link>
          </SidebarList>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  width: 20%;
  max-width: 250px;
  height: calc(100vh - 50px);
  background-color: rgb(251, 251, 255);
  position: sticky;
  top: 50px;
  border-radius: 10px;
`;

const SidebarWrapper = styled.div`
  padding: 20px;
  color: #555;
`;

const SidebarMenu = styled.div`
  margin-bottom: 10px;
`;

const SidebarList = styled.ul`
  list-style: none;
  padding: 5px;
`;
const SidebarListItem = styled.li`
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  &:hover {
    background-color: rgb(240, 240, 255);
  }
`;

const style = css`
  margin-right: 5px;
  font-size: 20px;
`;

const DashboardIcon = styled(LineStyle)`
  ${style}
`;

const PersonIcon = styled(PermIdentity)`
  ${style}
`;

const PostsIcon = styled(DynamicFeedOutlined)`
  ${style}
`;
export default Sidebar;
