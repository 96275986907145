import React from "react";
import { isFunctionExpression } from "typescript";
import { Settings } from "@mui/icons-material";
import styled from "styled-components";
import GigFixLogo from "../gigfix.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../components/Auth/auth";

const Navbar = () => {
  const randomNumber = () => Math.floor(Math.random() * 99) + 1;

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    auth.signout();
    navigate("/login", { replace: true });
  };

  return (
    <NavbarContainer>
      <NavbarWrapper>
        <TopLeft>
          <Logo src={GigFixLogo} alt="GigFix Logo" />
        </TopLeft>
        <TopRight>
          <IconContainer>
            <Logout
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Options />
            </Logout>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </IconContainer>
        </TopRight>
      </NavbarWrapper>
    </NavbarContainer>
  );
};

const NavbarWrapper = styled.div`
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavbarContainer = styled.div`
  width: 100%;
  height: 50px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 999;
`;

const TopLeft = styled.div`
  flex: 1;
  align-items: center;
  margin-top: 10px;
  margin-bottom: -5px;
  margin-left: -10px;
`;

const Options = styled(Settings)`
  margin-bottom: 1rem;
  margin-right: -0.5rem;
`;

const TopRight = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const IconContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 0;
  color: #555;
`;

const Logout = styled(Button)`
  width: 40px;
  height: 40px;
`;

const Logo = styled.img`
  width: 150px;
  height: 60px;
`;

export default Navbar;
