import "./App.css";
import React from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import UserList from "./pages/UserList";
import PostsList from "./pages/PostsList";
import Layout from "./components/Layout";

function Dashboard() {
  return <Layout children={<Home />} />;
}

export default Dashboard;

