export const KEY_TOKEN = "token";

export const auth = {
  isAuthenticated: !localStorage.getItem(KEY_TOKEN) ? false : true,
  signin(data) {
    auth.isAuthenticated = true;
    localStorage.setItem(KEY_TOKEN, data);
  },
  getData() {
    if (!auth.isAuthenticated) return null;
    return {
      access_token: localStorage.getItem(KEY_TOKEN),
    };
  },
  signout() {
    auth.isAuthenticated = false;
    localStorage.clear();
  },
};
