import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { getHomeData } from "../../../components/requests";

const Featured = () => {
  const [getData, setGetData] = useState([]);

  useEffect(() => {
    getHomeData().then((res) => setGetData(res.data));
  }, []);

  return (
    <>
      <FeaturedContainer>
        <FeaturedItem key={getData.newPosts}>
          <FeaturedTitle>New posts</FeaturedTitle>
          <FeaturedNumberContainer>
            <span className="featuredNumber">
              <ArrowUpward className="featuredIcon" />
              {getData.newPosts}
            </span>
          </FeaturedNumberContainer>
        </FeaturedItem>

        <FeaturedItem key={getData.newUsersThisWeek}>
          <FeaturedTitle>New users this week</FeaturedTitle>
          <FeaturedNumberContainer>
            <span className="featuredNumber">
              <ArrowUpward className="featuredIcon" />
              {getData.newUsersThisWeek}
            </span>
          </FeaturedNumberContainer>
        </FeaturedItem>

        <FeaturedItem key={getData.totalPosts}>
          <FeaturedTitle>Total posts</FeaturedTitle>
          <FeaturedNumberContainer>
            <span className="featuredNumber">
              <ArrowUpward className="featuredIcon" />
              {getData.totalPosts}
            </span>
          </FeaturedNumberContainer>
        </FeaturedItem>

        <FeaturedItem key={getData.totalUsers}>
          <FeaturedTitle>Total users</FeaturedTitle>
          <FeaturedNumberContainer>
            <span className="featuredNumber">
              <ArrowUpward className="featuredIcon" />
              {getData.totalUsers}
            </span>
          </FeaturedNumberContainer>
        </FeaturedItem>
      </FeaturedContainer>
    </>
  );
};

const FeaturedContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const FeaturedItem = styled.div`
  flex: 1;
  margin: 0px 20px;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
`;

const FeaturedTitle = styled.span`
  font-size: 20px;
  text-align: center;
`;

const FeaturedNumberContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  .featuredNumber {
    font-size: 25px;
    font-weight: 600;
  }
  .featuredIcon {
    font-size: 14px;
    margin-left: -10px;
    color: green;
    margin-right: 10px;
  }
`;

export default Featured;
