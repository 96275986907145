import React from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import styled from "styled-components";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="container">
        <Sidebar />
        <InnerChildren>{children}</InnerChildren>
      </div>
    </>
  );
};

const InnerChildren = styled.div`
  padding: 0 20px 0 20px;
  min-width: 80%;
  height: 100vh;
`;

export default Layout;
