import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import {
  List,
  PostVideo,
  PostPhoto,
  PostNone,
  DeletePost,
} from "../../styles/styled-elements";
import { getPost } from "../../../../components/requests";
import { CustomLoadingOverlay } from "./loadingBar";
import moment from "moment";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CardMedia from "@mui/material/CardMedia";
import { deletePost } from "../../../../components/requests";

const PreviewUserPosts = () => {
  const [userPosts, setuserPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [modalMedia, setModalMediaParams] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState({
    url: "",
    description: "",
    type: "",
    open: false,
  });
  const [deleted, setDeleted] = useState("");

  useEffect(() => {
    getPost(id).then((data) => {
      setuserPosts(data.data);
      setLoading(false);
    });
  }, []);

  const handleClickOpen = (e, url, description, type) => {
    e.preventDefault();
    setSelectedModal({
      url: url,
      description: description,
      type: type,
      open: true,
    });
  };

  const handleClickOpenModal = (row) => {
    setDeleted(row);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseAcceptModal = () => {
    deletePost(deleted);
    setOpen(false);
    setDeleted("");

    // TODO
    // getPosts().then((data) => {
    //   setTableData(data.data);
    //   setPostsData(data.data);
    //   setLoading(false);
    // });
  };

  function getPositions(params) {
    if (params.row.position) {
      const positions = params.row.position;
      return `${positions.parent.name || ""}, ${positions.name || ""}`;
    } else {
      return "";
    }
  }

  const handleCloseMediaModal = () => {
    setModalMediaParams([]);
    setOpen(false);
    setSelectedModal({ url: "", description: "", type: "", open: false });
  };

  const DialogRender = () => {
    return (
      <Dialog
        open={selectedModal.open}
        onClose={handleCloseMediaModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backgroundColor: "rgb(0 0 0 / 10%)",
            boxShadow: "none",
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"View Post Media"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h1>{selectedModal.description}</h1>
            <CardMedia
              style={{ maxWidth: "400px" }}
              component={selectedModal.type}
              image={selectedModal.url}
              maxHeight="200"
              autoPlay
              controls
            />
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMediaModal} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // console.log(userPosts);

  const columns = [
    {
      field: "url",
      headerName: "Post",
      width: 120,
      type: "string",
      renderCell: (params) => {
        if (
          ["jpg", "png"].includes(
            params.row.url.substr(params.row.url.length - 3).toLowerCase()
          )
        ) {
          return (
            <>
              <PostPhoto
                variant="outlined"
                onClick={(e) => {
                  setModalMediaParams([
                    params.row.url,
                    params.row.description,
                    "img",
                  ]);
                  handleClickOpen(
                    e,
                    params.row.url,
                    params.row.description,
                    "img"
                  );
                }}
              >
                Dialog
              </PostPhoto>
            </>
          );
        } else if (
          ["mp4", "mpg", "avi", "mov"].includes(
            params.row.url.substr(params.row.url.length - 3).toLowerCase()
          )
        ) {
          return (
            <>
              <PostVideo
                variant="outlined"
                onClick={(e) => {
                  setModalMediaParams([
                    params.row.url,
                    params.row.description,
                    "video",
                  ]);
                  handleClickOpen(
                    e,
                    params.row.url,
                    params.row.description,
                    "video"
                  );
                }}
              >
                Dialog
              </PostVideo>
            </>
          );
        } else {
          return (
            <>
              <PostNone
                variant="outlined"
                onClick={(e) => {
                  handleClickOpen(
                    e,
                    params.row.url,
                    params.row.description,
                    "img"
                  );
                }}
              >
                Dialog
              </PostNone>
            </>
          );
        }
      },
    },
    { field: "description", headerName: "Description", width: 200 },
    {
      field: "position",
      headerName: "Role",
      width: 300,
      type: "string",
      valueGetter: getPositions,
      renderCell: (params) => {
        return params.value;
      },
    },
    { field: "location", headerName: "Location", width: 200 },
    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
      type: "dateTime",
      valueGetter: (params) => moment(params.row.updatedAt).toDate(),
      renderCell: (params) => {
        return moment(params.row.updatedAt).format("MM/D/YYYY HH:mm");
      },
    },
    { field: "is_public", headerName: "Is public", width: 150 },
    { field: "id", headerName: "ID", width: 200 },
    {
      field: "deletePost",
      headerName: "Delete Post",
      width: 200,
      type: "string",
      renderCell: (params) => {
        return (
          <>
            <DeletePost
              variant="outlined"
              onClick={() => {
                handleClickOpenModal(params.row);
              }}
            >
              Open alert dialog
            </DeletePost>
            <Dialog
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              BackdropProps={{
                style: {
                  backgroundColor: "rgb(0 0 0 / 10%)",
                  boxShadow: "none",
                },
              }}
              PaperProps={{
                style: {
                  backgroundColor: "white",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {"Admin - Delete post"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this post?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal}>Disagree</Button>
                <Button onClick={handleCloseAcceptModal} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ListPreviewUser>
        <DataGrid
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          loading={loading}
          rows={userPosts}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
        />
      </ListPreviewUser>
      {DialogRender()}
    </>
  );
};

const ListPreviewUser = styled(List)`
  margin-top: 1rem;
  height: 30rem;
  width: 100%;
`;
export default PreviewUserPosts;
