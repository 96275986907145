import styled from "styled-components";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export const List = styled.div`
  height: 700px;
  width: 100%;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
`;

export const ListImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;

export const Delete = styled(DeleteOutline)`
  color: red;
  cursor: pointer;
  margin-left: 15px;
`;

export const Check = styled(CheckCircleIcon)`
  color: green;
`;

export const Uncheck = styled(CancelIcon)`
  color: red;
`;

export const Pending = styled(WatchLaterIcon)`
  color: black;
`;

export const DeleteUser = styled(DeleteForeverIcon)`
  color: red;
`;

export const DeletePost = styled(DeleteForeverIcon)`
  color: red;
`;

export const PostVideo = styled(OndemandVideoIcon)`
  color: blue;
`;

export const PostPhoto = styled(PhotoCameraIcon)`
  color: blue;
`;

export const PostNone = styled(HelpOutlineIcon)`
  color: blue;
`;

export const ItemContainer = styled.div`
  flex: 4;
  padding: 20px;
  border-radius: 20px;
  margin-top: -1.5rem;
`;
export const ItemTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemShowImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
`;

export const Edit = styled(EditOutlined)`
  width: 1rem;
  height: 1rem;
  margin-right: auto;
  margin-top: 0.2rem;
  cursor: pointer;
`;

export const EditButton = styled.button`
  width: 6rem;
  height: 2.3rem;
  background-color: #0176b2;
  font-size: 20px;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 18px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
`;
