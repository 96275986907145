import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";

import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  AttachMoney,
  DynamicFeed,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import {
  ItemContainer,
  ItemTitleContainer,
  ItemShowImg,
  EditButton,
  Check,
  Uncheck,
  Edit,
  DeleteUser,
  Pending,
} from "../styles/styled-elements";
import styled from "styled-components";
import Layout from "../components/Layout";
import {
  getUser,
  updateUser,
  getExperiences,
  getBackground,
  getParentPositions,
  getChildrenPositions,
} from "../../../components/requests";
import TextField from "@mui/material/TextField";
import { resetUser } from "../../../components/requests";
import { checkUsernameEmail } from "../../../components/requests";
import { deleteUserImage } from "../../../components/requests";
import { useNavigate } from "react-router-dom";
import PreviewUserPosts from "./components/PreviewUserPosts";
import PreviewUserInvites from "./components/PreviewUserInvites";
import { Box } from "@mui/material";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";

const UserPreview = () => {
  const [edit, setEdit] = useState(false);
  const [bio, setBio] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [username, setUsername] = useState("");
  const [partnerUser, setPartnerUser] = useState(false);
  const [invited, setInvited] = useState(false);
  const [reviewStatus, setReviewStatus] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [rate, setRate] = useState("");
  const [email, setEmail] = useState("");
  const [received, setReceived] = useState(false);
  const [userId, setUserId] = useState("");
  const [phoneKey, setPhoneKey] = useState("");
  const [fullPhoneNumber, setFullPhoneNumber] = useState("");
  const [textCancelButton, setTextCancelButton] = useState("Save");
  const [position, setPosition] = useState([]);
  const [background, setBackground] = useState([]);
  const [experience, setExperience] = useState([]);
  const [location, setLocation] = useState(null);
  const { id } = useParams();
  const [reseted, setReseted] = useState("");
  const [open, setOpen] = useState(false);
  const [allExperiences, setAllExperiences] = useState([]);
  const [allBackgrounds, setAllBackgrounds] = useState([]);
  const [allChildrenPositions, setAllChildrenPositions] = useState([]);
  const [allParentPositions, setAllParentPositions] = useState([]);
  const [userExperience, setUserExperience] = useState([]);
  const [userBackground, setUserBackground] = useState([]);
  const [userPosition, setUserPosition] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!!userExperience || !!allExperiences) {
      let arr = [];
      for (let i = 0; i < userExperience.length; i++) {
        const exp = allExperiences.find((x) => x.id == userExperience[i].id);
        if (!!exp) {
          arr.push(exp);
        }
      }
      setExperience(arr);
    }
  }, [userExperience, allExperiences]);

  useEffect(() => {
    if (!!userPosition && !!allParentPositions) {
      let arr = [];
      for (let i = 0; i < userPosition.length; i++) {
        for (let j = 0; j < allParentPositions.length; j++) {
          const pos = allParentPositions[j].childrens.find(
            (x) => x.id === userPosition[i].id
          );
          if (!!pos) {
            arr.push(pos);
          }
        }
      }
      setPosition(arr);
    }
  }, [userPosition, allParentPositions]);

  useEffect(() => {
    if (!!userBackground || !!allBackgrounds) {
      let arr = [];
      for (let i = 0; i < userBackground.length; i++) {
        const back = allBackgrounds.find((x) => x.id === userBackground[i].id);
        if (!!back) {
          arr.push(back);
        }
      }
      setBackground(arr);
    }
  }, [userBackground, allBackgrounds]);

  const handler = async (e) => {
    setEdit(true);
  };

  const saveUser = async (e) => {
    if (bio.length > 140) {
      alert(`The bio cannot exceed 140 characters`);
      return;
    }

    if (lastName.length > 25) {
      alert(`The lastname cannot exceed 25 characters`);
      return;
    }

    if (firstName.length > 25) {
      alert(`The firstname cannot exceed 25 characters`);
      return;
    }

    if (username.length > 25) {
      alert(`The username cannot exceed 25 characters`);
      return;
    }

    setTextCancelButton("Saved!");
    if (!isValidPhoneNumber(fullPhoneNumber)) {
      alert(`invalid phone: ${fullPhoneNumber}`);
      return;
    }
    const phoneData = parsePhoneNumber(fullPhoneNumber);
    const code = phoneData.countryCallingCode.includes("+")
      ? phoneData.countryCallingCode
      : `+${phoneData.countryCallingCode}`;
    setPhoneKey(phoneData.country);

    const data = {
      lastname: lastName,
      firstname: firstName,
      phone_number: {
        country: {
          code,
          key: phoneData.country,
        },
        phone: phoneData.nationalNumber,
      },
      review_status: reviewStatus,
      partner_user: partnerUser,
      username: username,
      email: email,
      rate: Number(rate),
      bio: bio,
      experience: experience.map((row) => row.id),
      background: background.map((row) => row.id),
      position: position.map((row) => row.id),
    };

    const userAvailable = await checkUsernameEmail(
      data.username,
      data.email
    ).then((res) => {
      if (!res.data.isAvailable) {
        alert(`The username is NOT available`);
        return;
      }
      return true;
    });

    if (!userAvailable) {
      return false;
    }

    return await updateUser(data, id).then((res) => {
      setTextCancelButton("Save");
      const { data: user } = res;
      setLastName(user.lastname || "");
      setPartnerUser(user.partner_user);
      setReviewStatus(user.review_status);
      setFirstName(user.firstname || "");
      setUsername(user.username || "");
      setBio(user.bio || "");
      setRate(user.rate || "");
      setPhoneKey(
        typeof user.phone_number !== "undefined"
          ? user.phone_number.country.key || ""
          : ""
      );
      setProfileImage(
        user.profile_image_url ||
          "https://gigfix-bucket.s3.amazonaws.com/img/gigfix_icon.png"
      );
      setEdit(false);
      let arrExp = [];
      for (let i = 0; i < user.experience.length; i++) {
        const exp = allExperiences.find((x) => x.id == user.experience[i].id);
        if (!!exp) {
          arrExp.push(exp);
        }
      }
      setExperience(arrExp);

      let arrBack = [];
      for (let i = 0; i < user.background.length; i++) {
        const exp = allBackgrounds.find((x) => x.id === user.background[i].id);
        if (!!exp) {
          arrBack.push(exp);
        }
      }
      setBackground(arrBack);

      let arrPos = [];
      for (let i = 0; i < user.position.length; i++) {
        for (let j = 0; j < allParentPositions.length; j++) {
          const exp = allParentPositions[j].childrens.find(
            (x) => x.id === user.position[i].id
          );
          if (!!exp) {
            arrPos.push(exp);
          }
        }
      }
      setPosition(arrPos);
    });
  };

  const loadData = () => {
    getUser(id).then((res) => {
      const { data: user } = res;
      const city = user.city
        ? user.city.secondary
          ? user.city.primary + " | " + user.city.secondary
          : user.city.primary
        : "";
      setLastName(user.lastname || "");
      setPartnerUser(user.partner_user);
      setInvited(user.invited);
      setReviewStatus(user.review_status);
      setFirstName(user.firstname || "");
      setUsername(user.username || "");
      setBio(user.bio || "");
      setRate(user.rate || "");
      setEmail(user.email || "");
      if (user.phone_number) {
        setPhoneKey(user.phone_number.country.key);
        setFullPhoneNumber(
          `${user.phone_number.country.code}${user.phone_number.phone}`
        );
      } else {
        setPhoneKey("");
        setFullPhoneNumber("");
      }
      setProfileImage(
        user.profile_image_url ||
          "https://gigfix-bucket.s3.amazonaws.com/img/gigfix_icon.png"
      );
      setUserPosition(user.position || []);
      setUserBackground(user.background || []);
      setUserExperience(user.experience || []);
      setLocation(city);
      setReceived(true);
      setUserId(user.id);
    });

    getExperiences().then((res) => {
      setAllExperiences(res.data);
    });

    getBackground().then((res) => {
      setAllBackgrounds(res.data);
    });

    getChildrenPositions().then((res) => {
      setAllChildrenPositions(res.data);
    });

    getParentPositions().then((res) => {
      setAllParentPositions(res.data);
    });
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleClickOpenModal = (id) => {
    setOpenModal(true);
  };

  const handleCloseAcceptModal = () => {
    deleteUserImage(id);
    setProfileImage("");
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const cancelEdit = (e) => {
    setEdit(false);
    loadData();
  };

  const sendResetPassword = (email) => {
    setOpen(true);
    setReseted(email);
  };

  const handleClose = () => {
    setOpen(false);
    setReseted("");
  };

  const handleCloseAccept = () => {
    resetUser(email);
    setOpen(false);
    setReseted("");
  };

  const navigate = useNavigate();

  const goback = async (e) => {
    window.close();
  };

  const handleChangeExperience = (event) => {
    const {
      target: { value },
    } = event;

    setExperience(value);
  };

  const handleChangeBackground = (event) => {
    const {
      target: { value },
    } = event;

    setBackground(value);
  };

  const handleChangePosition = (event) => {
    const {
      target: { value },
    } = event;

    setPosition(value);
  };

  const renderValuePosition = () => {
    const positionNames = position.map((row) => {
      return row.name;
    });
    return positionNames.join(", ");
  };

  const renderValueExperience = () => {
    const experiencesNames = experience.map((row) => {
      return row.name;
    });
    return experiencesNames.join(", ");
  };

  const renderValueBackground = () => {
    const backgroundNames = background.map((row) => {
      return row.name;
    });
    return backgroundNames.join(", ");
  };

  return (
    <Layout>
      {received && (
        <ItemContainer>
          <ItemTitleContainer>
            <h1>View user</h1>
            {!edit ? (
              <>
                <Edit onClick={handler}></Edit>
                <ResetPasswordButton
                  onClick={() => {
                    sendResetPassword(email);
                  }}
                >
                  Reset Password
                </ResetPasswordButton>
                <EditButton onClick={goback}>Return</EditButton>

                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  BackdropProps={{
                    style: {
                      backgroundColor: "rgb(0 0 0 / 10%)",
                      boxShadow: "none",
                    },
                  }}
                  PaperProps={{
                    style: {
                      backgroundColor: "white",
                      boxShadow: "none",
                    },
                  }}
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Admin - Reset Password"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to send email to user for reset
                      password?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleCloseAccept} autoFocus>
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : (
              <>
                <CancelButton onClick={cancelEdit}>Cancel</CancelButton>
                <EditButton onClick={saveUser}>{textCancelButton}</EditButton>
              </>
            )}
          </ItemTitleContainer>
          <UserContainer>
            <ShowUser>
              <ShowUserTop>
                {!edit ? (
                  <ItemShowImg
                    src={
                      profileImage ||
                      "https://gigfix-bucket.s3.amazonaws.com/img/gigfix_icon.png"
                    }
                    alt="show-image"
                  />
                ) : null}
                <ShowTopTitle>
                  <FontWeight bolder>
                    {edit ? (
                      <Edited>
                        <TextField
                          id="standard-basic"
                          label="Last Name"
                          defaultValue={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <></>
                        <TextField
                          id="standard-basic"
                          label="First Name"
                          defaultValue={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Edited>
                    ) : (
                      <>{`${lastName}, ${firstName}`}</>
                    )}
                  </FontWeight>
                  <Handle>
                    {edit ? (
                      <Edited>
                        <TextField
                          id="username"
                          label="Handle"
                          defaultValue={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </Edited>
                    ) : (
                      <>{`@${username}`}</>
                    )}
                  </Handle>
                </ShowTopTitle>
              </ShowUserTop>
              <FontWeight>
                {!edit ? (
                  <>
                    {position !== undefined
                      ? position.map((position) => (
                          <ListItems key={position.id}>
                            {position.name}
                          </ListItems>
                        ))
                      : null}
                  </>
                ) : (
                  <>
                    <FormControl
                      sx={{ m: 1, width: "100%", margin: "10px 0px 0px 0px" }}
                      size="small"
                    >
                      <InputLabel id="Role">Role</InputLabel>
                      <Select
                        labelId="Role"
                        id="exp"
                        multiple
                        value={position}
                        onChange={handleChangePosition}
                        input={<OutlinedInput label="Positions" />}
                        renderValue={renderValuePosition}
                      >
                        {allParentPositions.map((row) =>
                          row.childrens.map((child) => (
                            <MenuItem key={child.id} value={child}>
                              <ListItemText
                                primary={
                                  row.name.charAt(0).toUpperCase() +
                                  row.name.slice(1) +
                                  " - " +
                                  child.name.charAt(0).toUpperCase() +
                                  child.name.slice(1)
                                }
                              />
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      <PositionMessageBox>
                        <PositionMessage>select up to 3 roles</PositionMessage>
                      </PositionMessageBox>
                    </FormControl>
                  </>
                )}
              </FontWeight>
              <Bio>
                {edit ? (
                  <TextField
                    id="standard-multiline-flexible"
                    label="Bio"
                    multiline
                    fullWidth
                    maxRows={4}
                    defaultValue={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                ) : (
                  <>{bio}</>
                )}
              </Bio>
              <ShowUserBottom>
                <UserShowTitle>Account Details</UserShowTitle>
                <UserShowInfo>
                  {edit ? (
                    <>
                      <Details className="showInfoTitle">Partner User</Details>
                      <Switch
                        checked={partnerUser}
                        onChange={(event) =>
                          setPartnerUser(event.target.checked)
                        }
                      />
                    </>
                  ) : (
                    <>
                      {partnerUser ? (
                        <Check className="showIcon" />
                      ) : (
                        <Uncheck className="showIcon" />
                      )}
                      <Details className="showInfoTitle">Partner User</Details>
                    </>
                  )}
                </UserShowInfo>
                {!edit && invited && (
                  <UserShowInfo>
                    <Check className="showIcon" />
                    <Details className="showInfoTitle">Invited</Details>
                  </UserShowInfo>
                )}
                <UserShowInfo>
                  {edit ? (
                    <>
                      <FormControl
                        sx={{ m: 1, width: "100%", margin: "5px 0px 10px 0px" }}
                        size="small"
                      >
                        <InputLabel id="status">Status</InputLabel>
                        <Select
                          labelId="status"
                          id="reviewStatus"
                          value={reviewStatus}
                          onChange={(event) =>
                            setReviewStatus(event.target.value)
                          }
                          input={<OutlinedInput label="Status" />}
                        >
                          <MenuItem value={"accepted"}>
                            <ListItemText primary={"Accepted"} />
                          </MenuItem>
                          <MenuItem value={"rejected"}>
                            <ListItemText primary={"Rejected"} />
                          </MenuItem>
                          <MenuItem value={"pending"}>
                            <ListItemText primary={"Pending"} />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <>
                      {reviewStatus === "accepted" && (
                        <Check className="showIcon" />
                      )}
                      {reviewStatus === "rejected" && (
                        <Uncheck className="showIcon" />
                      )}
                      {reviewStatus === "pending" && (
                        <Pending className="showIcon" />
                      )}

                      <Details className="showInfoTitle">Review status</Details>
                    </>
                  )}
                </UserShowInfo>
                <UserShowInfo>
                  {!edit ? (
                    <>
                      <CalendarToday className="showIcon" />
                      <Details className="showInfoTitle">Background: </Details>
                      <>
                        {background !== undefined
                          ? background.map((background) => (
                              <ListItems key={background.id}>
                                {background.name}
                              </ListItems>
                            ))
                          : null}
                      </>
                    </>
                  ) : (
                    <>
                      <FormControl
                        sx={{ m: 1, width: "100%", margin: "5px 0px 10px 0px" }}
                        size="small"
                      >
                        <InputLabel id="Background">Background</InputLabel>
                        <Select
                          labelId="Background"
                          id="exp"
                          multiple
                          value={background}
                          onChange={handleChangeBackground}
                          input={<OutlinedInput label="Background" />}
                          renderValue={renderValueBackground}
                        >
                          {allBackgrounds.map((row) => (
                            <MenuItem key={row.id} value={row}>
                              <ListItemText primary={row.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                  {/* {edit ? (
                    <Edited>
                      <TextField id="background" label="Background" />
                    </Edited>
                  ) : (
                    here goes background
                  )} */}
                </UserShowInfo>
                <UserShowInfo>
                  {!edit ? (
                    <>
                      <PermIdentity className="showIcon" />
                      <Details className="showInfoTitle">Experience: </Details>
                      <>
                        {experience !== undefined
                          ? experience.map((exp) => (
                              <ListItems key={exp.id}>{exp.name}</ListItems>
                            ))
                          : null}
                      </>
                    </>
                  ) : (
                    <>
                      <FormControl
                        sx={{ m: 1, width: "100%", margin: "5px 0px 10px 0px" }}
                        size="small"
                      >
                        <InputLabel id="Experience">Experience</InputLabel>
                        <Select
                          labelId="Experience"
                          id="exp"
                          multiple
                          value={experience}
                          onChange={handleChangeExperience}
                          input={<OutlinedInput label="Experience" />}
                          renderValue={renderValueExperience}
                        >
                          {allExperiences.map((row) => (
                            <MenuItem key={row.id} value={row}>
                              <ListItemText primary={row.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                  {/* {edit ? (
                    <Edited>
                      <TextField id="experience" label="Experience" />
                    </Edited>
                  ) : (
                    here goes experience
                  )} */}
                </UserShowInfo>
                <UserShowInfo>
                  <AttachMoney className="showIcon" />
                  <span
                    className="showInfoTitle"
                    style={{ "margin-right": "5px" }}
                  >
                    Rate:
                  </span>
                  {edit ? (
                    <>
                      <Edited>
                        <Box
                          component={"form"}
                          sx={{
                            marginTop: 16,
                            "& > :not(style)": { m: 1, width: "5ch" },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            id="rate"
                            placeholder=" "
                            InputLabelProps={{ shrink: true }}
                            defaultValue={rate && rate > 0 ? rate : null}
                            onChange={(e) => setRate(e.target.value)}
                          />
                        </Box>
                      </Edited>
                      <span>USD/ hr</span>
                    </>
                  ) : (
                    <>{rate && rate > 0 ? `${rate} USD / hr` : null}</>
                  )}
                </UserShowInfo>
                <UserShowTitle>Contact Details</UserShowTitle>
                <UserShowInfo>
                  <PhoneAndroid className="showIcon" />
                  <span className="showInfoTitle"></span>
                  <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry="US"
                    countryCallingCodeEditable={false}
                    withCountryCallingCode={true}
                    country={phoneKey}
                    disabled={!edit}
                    international={true}
                    value={fullPhoneNumber}
                    onChange={setFullPhoneNumber}
                  />
                </UserShowInfo>
                <UserShowInfo>
                  {!edit ? (
                    <>
                      <MailOutline className="showIcon" />
                      <span className="showInfoTitle"></span>
                      {email}
                    </>
                  ) : null}
                  {/* {edit ? (
                    <Edited>
                      <TextField id="email" label="email" />
                    </Edited>
                  ) : (
                    here goes email
                  )} */}
                </UserShowInfo>
                <UserShowInfo>
                  {location !== null && !edit ? (
                    <>
                      <LocationSearching className="showIcon" />
                      <span className="showInfoTitle"></span>
                      {`${location}`}
                    </>
                  ) : null}
                  {/* {edit ? (
                    <Edited>
                      <TextField id="location" label="Location" />
                    </Edited>
                  ) : (
                    <>Argentina | Santa Fe</>
                  )} */}
                </UserShowInfo>
              </ShowUserBottom>
            </ShowUser>
            {edit ? (
              <>
                <ImageContainer>
                  <UsernameEditScreen
                    bolder
                  >{`@${username}`}</UsernameEditScreen>
                  <EditImage
                    src={
                      profileImage ||
                      "https://gigfix-bucket.s3.amazonaws.com/img/gigfix_icon.png"
                    }
                    alt="show-image"
                  />
                  <ImageContainerFooter>
                    <DeleteUser
                      onClick={() => {
                        handleClickOpenModal(userId);
                      }}
                    ></DeleteUser>
                    Remove user image
                  </ImageContainerFooter>
                </ImageContainer>
                <Dialog
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  BackdropProps={{
                    style: {
                      backgroundColor: "rgb(0 0 0 / 10%)",
                      boxShadow: "none",
                    },
                  }}
                  PaperProps={{
                    style: {
                      backgroundColor: "white",
                      boxShadow: "none",
                    },
                  }}
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Admin - Delete user image"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete the user image?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseModal}>Disagree</Button>
                    <Button onClick={handleCloseAcceptModal} autoFocus>
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : null}
          </UserContainer>
          {!edit ? (
            <>
              <PostTitleContainer>
                <h1 style={{ "margin-top": "1rem" }}>Posts</h1>
                <FeedIcon />
              </PostTitleContainer>
              <PreviewUserPosts />
              <PostTitleContainer>
                <h1 style={{ "margin-top": "1rem" }}>Invites</h1>
              </PostTitleContainer>
              <PreviewUserInvites />
            </>
          ) : null}
        </ItemContainer>
      )}
    </Layout>
  );
};

const UserContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;
const ShowUser = styled.div`
  flex: 1;
  padding: 20px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  width: 1rem;
  border-radius: 20px;
`;

const ShowUserTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;
const ShowUserBottom = styled.div`
  margin-top: 20px;
`;
const ShowTopTitle = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-left: 20px; */
`;
const FontWeight = styled.span`
  font-weight: ${(props) => (props.bolder ? "600" : "400")};
`;
const UserShowTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
`;
const UserShowInfo = styled.div`
  margin: 10px 0px;
  color: #444;
  alignitems: center;
  display: flex;
  .showIcon {
    font-size: 16px !important;
  }
  .showInfoTitle {
    margin-left: 10px;
  }
`;

const Handle = styled.span`
  font-weight: 600;
  font-size: 0.8rem;
`;

const Bio = styled.div`
  margin-top: 1rem;
  margin-bottom: -0.5rem;
  font-size: 0.8rem;
  font-weight: 100;
`;

const Edited = styled.span`
  margin-bottom: 1rem;
  /* margin-left: 0.5rem; */
`;

const ImageContainer = styled.div`
  flex: 2;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ImageContainerFooter = styled.div`
  padding: 20px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
`;

const EditImage = styled(ItemShowImg)`
  margin: auto;
  display: block;
  width: 30rem;
  height: 30rem;
`;

const UsernameEditScreen = styled(FontWeight)`
  display: block;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;
const ListItems = styled.li`
  display: inline-block;
  margin: 1px 3px 0 0;
  background-color: rgb(38 153 210);
  /* background-color: #0176b2; */
  border-radius: 20px;
  color: white;
  font-weight: 700;
  font-size: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 6px;
`;

const Details = styled.span`
  white-space: pre-wrap;
`;

const CancelButton = styled(EditButton)`
  background-color: red;
  margin-left: auto;
  margin-right: 0.3rem;
`;

const ResetPasswordButton = styled.button`
  width: 14rem;
  height: 2.3rem;
  background-color: red;
  font-size: 20px;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 18px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  margin-right: 0.3rem;
`;

const PostTitleContainer = styled(ItemTitleContainer)`
  margin-right: 80rem;
`;

const FeedIcon = styled(DynamicFeed)`
  margin-top: 20px;
`;

const PositionMessageBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2px;
`;

const PositionMessage = styled.span`
  font-size: 12px;
  opacity: 60%;
`;

export default UserPreview;
